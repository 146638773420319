.App {
  text-align: center;
  background-color: #1B2219;
  color: #ffffff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#bio {
  color: #ffffff; 
}

.bio-img {
  align-content: center;
}

.mainCard {
  background: #1B2219 !important;
}

.cardButton {
  background: #1B2219 !important;
}

#footer {
  padding: 20px;
  margin: 50px 0px;
  color: #ffffff;
}

#footer img {
  height: 100px;
  width: 100px;
}

.footerText {
  padding: 50px 0px;
}

.navlink {
  color: #ffffff !important;
}
